.loading-background {
  z-index: 9999;
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  background-color: #00164e5e !important;

  & > * {
    position: relative !important;
    left: 46% !important;
    margin-left: -50px !important;
    top: 50% !important;
    margin-top: -50px !important;
  }

  & > p {
    color: white;
    position: absolute !important;
    left: 0 !important;
    right: 0;
    text-align: center;
  }
}
