.navlink {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0px;
  transition: 300ms;
}

.navlink:hover {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.267);
  color: #cbcecf;
  text-decoration: none;
}

.active-navlink {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.075);
  color: white;
  cursor: unset;
}

.active-navlink:hover {
  background-color: rgba(255, 255, 255, 0.075);
  color: white;
  text-decoration: none;
}

.header-brand {
  color: #fff;
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none;
  transition: 300ms;
}

.header-brand:hover {
  color: #cbcecf;
  text-decoration: none;
}
